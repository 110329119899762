import { DealStatus, DealTypeExplicit, Deals } from "@/lib/interfaces/Deals";
import { ExpertWithWrappers } from "@/lib/interfaces/ExpertWithWrappers";
import { InstanceRequest } from "@/lib/interfaces/InstanceRequest";
import { ModelWrapper } from "@/lib/interfaces/ModelWrapper";
import { Up2TomDownloadedModel } from "@/lib/interfaces/Up2TomDownloadedModel";
import { UsageAudit, UsageAuditType } from "@/lib/interfaces/UsageAudit";
import { UsageAuditsAggregated } from "@/lib/interfaces/UsageAuditsAggregated";
import { User, UserProfileData } from "@/lib/interfaces/User";
import { post } from "@/lib/util/api";
import { waitAtLeast } from "@/lib/util/wait";
import { KYCSchema } from "@aiea/lib/src/interfaces/KYC";
import { UP2TOM_API_HOST } from "app/config";
import { DealPostSchema } from "pages/api/deals";
import { Option } from "prelude-ts";
import * as zod from "zod";

interface ExtraDealsData {
  model: ModelWrapper[];
  expert: User[];
  cons: User[];
}
interface ExtendedDeals extends Deals, ExtraDealsData {}

class _Api {
  constructor() {}

  async login(email: string, password: string): Promise<User> {
    const response = await fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error("Error logging in");
    }

    const { user } = await response.json();
    return user;
  }

  logout() {
    return fetch("/api/logout", { method: "POST" });
  }

  async createAccount(
    email: string,
    password: string,
    token: string,
  ): Promise<User> {
    const response = await fetch("/api/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, token }),
    });
    return await response.json();
  }

  requestCredentials(email: string) {
    return fetch("/api/request-credentials", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
  }

  requestAccess(name: string, email: string) {
    return fetch("/api/request-access", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email }),
    });
  }

  async fetchInstanceRequests(): Promise<InstanceRequest[]> {
    const response = await fetch("/api/instance-requests");
    const instanceRequests: InstanceRequest[] = await response.json();
    return instanceRequests;
  }

  async requestInstance(): Promise<InstanceRequest> {
    const response = await fetch("/api/instance-requests", { method: "POST" });
    const instanceRequest: InstanceRequest = await response.json();
    return instanceRequest;
  }

  async getUp2TomTokenForCredentials(
    email: string,
    password: string,
  ): Promise<Option<string>> {
    try {
      const response = await post(`/api/up2tom-token`, {
        email,
        password,
      });
      const { token } = await response.json();
      if (token) {
        return Option.some(token);
      } else {
        throw new Error("Missing token in JSON");
      }
    } catch {
      return Option.none();
    }
  }

  async getUp2TomProfile(token: string): Promise<
    Option<{
      email: string;
      username: string;
      firstName: string;
      lastName: string;
      profile: {
        avatar: string;
        isAvatarVisible: boolean;
        education: string;
        experience: string;
        location: string;
        notes: string;
        position: string;
        skills: string[];
      };
    }>
  > {
    try {
      const response = await fetch(`${UP2TOM_API_HOST}/user/profile`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      const userData = await response.json();
      return Option.some(userData);
    } catch (e) {
      return Option.none();
    }
  }

  async getUp2TomModel(id: string): Promise<Option<Up2TomDownloadedModel>> {
    // TODO extract this to environment variable
    const AIEA_TOKEN = "some-secret";
    try {
      const response = await fetch(
        `${UP2TOM_API_HOST}/aiea/download-model/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${AIEA_TOKEN}`,
          },
        },
      );
      const model: Up2TomDownloadedModel = await response.json();
      return Option.ofNullable(model);
    } catch {
      return Option.none();
    }
  }

  async updateModel({
    modelID,
    wrapperID,
  }: {
    modelID: string;
    wrapperID: string;
  }) {
    try {
      const response = await fetch(`api/update-model`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ modelID, wrapperID }),
      });

      if (response.ok) {
        return 1;
      }
      return 0;
    } catch {
      return Option.none();
    }
  }

  sendContactEmail(email: string, name: string, message: string) {
    return fetch("/api/contact-aiea", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, name, message }),
    });
  }

  sendRequestExpertEmail(email: string, name: string, message: string) {
    return fetch("/api/request-expert", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, name, message }),
    });
  }

  async getSectors(): Promise<string[]> {
    const response = await fetch("/api/sectors");
    return await response.json();
  }

  async getSessionUser(): Promise<User> {
    const response = await fetch(`/api/user`);
    return await response.json();
  }

  async getUserById(id: string): Promise<User> {
    const response = await fetch(`/api/user/${id}`);
    return await response.json();
  }

  async getUserProfile(): Promise<UserProfileData> {
    const response = await fetch(`/api/user-profile`);
    return await response.json();
  }

  async getUserProfileById(id: string): Promise<UserProfileData> {
    const response = await fetch(`/api/user-profile/${id}`);
    return await response.json();
  }

  // async getUsageById(id: string): Promise<Option<UserUsage[]>> {
  //   try {
  //     const response = await fetch(`/api/usage/${id}`);
  //     const usage: UserUsage[] = await response.json();
  //     return Option.ofNullable(usage);
  //   } catch (e) {
  //     return Option.none();
  //   }
  // }

  async getUsage(): Promise<UsageAuditsAggregated[]> {
    const response = await fetch(`/api/usage`);
    return await response.json();
  }

  async createUsageAudit(
    user: User,
    wrapper: ModelWrapper,
    type: UsageAuditType,
    count: Number,
  ): Promise<UsageAudit> {
    const response = await fetch("/api/usage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: user._id,
        wrapper: wrapper._id,
        type,
        count,
      }),
    });
    return await response.json();
  }

  // async updateUsage(
  //   userUsage: UserUsage
  // ) {
  //   try {
  //     await fetch(`/api/usage/${userUsage._id}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(userUsage),
  //     });
  //   }
  //   catch (e) {
  //     console.log(e);
  //   }
  // }

  // async createUsageForUser(user: User, wrapperId: string, singleUsage: DailyUsage[], batchUsage: DailyUsage[]): Promise<UserUsage> {
  //   const response = await fetch(`/api/usage/create-for-user`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ user: user, wrapperId: wrapperId, singleUsage: singleUsage, batchUsage: batchUsage }),
  //   });

  //   return await response.json();
  // }

  async updateUserProfile(
    userProfileData: UserProfileData,
  ): Promise<UserProfileData> {
    const response = await fetch(`/api/user-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userProfileData),
    });
    return await response.json();
  }

  async getTermsAndConditions(): Promise<string> {
    const response = await fetch("/documents/AIEA_Terms.txt");
    return await response.text();
  }

  async importModel(modelId: string, userId: string): Promise<void> {
    await fetch(`/api/import-model/${modelId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    });
  }

  async syncAccount(): Promise<void> {
    await waitAtLeast(2000)(fetch("/api/sync-account", { method: "POST" }));
  }

  async getExpertsWithWrappers(): Promise<ExpertWithWrappers[]> {
    const response = await fetch("/api/experts-with-wrappers");
    return await response.json();
  }

  async getAllUsers(): Promise<User[]> {
    const response = await fetch("/api/users/all");
    return await response.json();
  }

  async getExpertWrappers(): Promise<ModelWrapper[]> {
    const response = await fetch("/api/wrappers");
    return await response.json();
  }

  async sendDealRequest(deal: zod.infer<typeof DealPostSchema>): Promise<void> {
    const response = await fetch("/api/deals", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(deal),
    });
    if (!response.ok) {
      throw new Error("Error sending deal request");
    }
    return await response.json();
  }

  async getDeals(
    type: DealTypeExplicit,
    status: DealStatus,
  ): Promise<{
    data: ExtendedDeals[];
  }> {
    const response = await fetch(`/api/deals?type=${type}&status=${status}`);
    if (!response.ok) {
      throw new Error("Error getting incoming deals");
    }
    return await response.json();
  }

  async getDeal(id: string): Promise<{
    data: ExtendedDeals;
  }> {
    const response = await fetch(`/api/deals/${id}?type=incoming`);
    if (!response.ok) {
      throw new Error("Error getting deal");
    }
    return await response.json();
  }

  async cancelDealRequest(id: string): Promise<{
    data: {
      message: string;
    };
  }> {
    const response = await fetch(`/api/deals/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "cancel" }),
    });
    if (!response.ok) {
      throw new Error("Error canceling deal");
    }
    return await response.json();
  }

  async deleteDealRequest(id: string): Promise<{
    data: {
      message: string;
    };
  }> {
    const response = await fetch(`/api/deals/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "delete" }),
    });
    if (!response.ok) {
      throw new Error("Error canceling deal");
    }
    return await response.json();
  }

  async submitKYC(data: any): Promise<{
    data: {
      message: string;
    };
  }> {
    const response = await fetch(`/api/kyc`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          business: data,
        },
      }),
    });

    if (!response.ok) {
      throw new Error("Error Submitting KYC");
    }

    return await response.json();
  }

  async getKYC(): Promise<{
    data: {
      kyc: any;
    };
  }> {
    const response = await fetch(`/api/kyc`);

    if (!response.ok) {
      throw new Error("Error Submitting KYC");
    }

    return await response.json();
  }
}

export const Api = new _Api();
